import { useContext } from "react";
import LoginContext from "../store/loginContext";

const useSidebarNav = () => {
  const loginCtx = useContext(LoginContext);
  const isAdmin = loginCtx.userRole === "admin";

  const sidebarNav = [
    {
      link: "/",
      section: "dashboard",
      icon: "lucide:layout-dashboard",
      text: "Dashboard",
    },
    {
      link: "/library",
      section: "library",
      icon: "solar:music-library-linear",
      text: "Library",
    },
  ];

  if (isAdmin) {
    sidebarNav.push({
      link: "/customers",
      section: "customers",
      icon: "ph:users-bold",
      text: "Customers",
    });
    sidebarNav.push({
      link: "/feedbacks",
      section: "feedbacks",
      icon: "ic:outline-feedback",
      text: "Feedbacks",
    });
  }

  return sidebarNav;
};

export default useSidebarNav;
