import React, { useContext, useEffect } from "react";
import LoginContext from "../store/loginContext";

import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

const AuthLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const loginCtx = useContext(LoginContext);
  const [token, setToken] = React.useState<string | null>(null);
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    const isInstall = searchParams.get("install");
    console.log("isInstall", isInstall);
    if (isInstall) {
      setToken("installToken");
      return;
    } else if (loginCtx.isLogin) {
      setToken(loginCtx.token);
    } else {
      navigate("/login");
    }
  }, []);
  return token ? (
    <Outlet context={{token: token}} />
  ) : <div>Redirecting...</div>
};

export default AuthLayout;
