import React from "react";
import InstallBox from "../components/install/Install";

function Install() {
  return (
    <section>
      <InstallBox />
    </section>
  );
}

export default Install;
