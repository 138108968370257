import React from "react";

import classes from "./Button.module.scss";

interface Props<T extends HTMLElement = HTMLButtonElement> {
  type?: "button" | "submit";
  onClick?: (e: React.MouseEvent<T, MouseEvent>) => void;
  outline?: boolean;
  color?: string;
  children: React.ReactNode;
}

const Button: React.FC<Props> = (props) => {
  return (
    <button
      className={`${classes.btn} ${props.outline ? classes.outline : classes.button} ${props.color ? classes[props.color] : ""}`}
      type={props.type || "button"}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

export default Button;
