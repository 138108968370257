import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainLayout from "./layout/MainLayout";
import AuthLayout from "./layout/AuthLayout";
import LoadingSpinner from "./components/UI/loadingSpinner/LoadingSpinner";
import "./scss/App.scss";
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import RegisterBeta from "./pages/RegisterBeta";
import Install from "./pages/Install";
import { Helmet } from "react-helmet";
import { images } from "./constants";
import { deleteDatabase, CACHE_EXPIRY_TIME } from './utils/indexedDBUtils';

const Dashboard = React.lazy(() => import("./pages/Dashboard"));
const Customers = React.lazy(() => import("./pages/Customers"));
const CustomerEdit = React.lazy(() => import("./pages/CustomerEdit"));
const NotFound = React.lazy(() => import("./pages/NotFound"));
const BlankPage = React.lazy(() => import("./pages/BlankPage"));
const Library = React.lazy(() => import("./pages/Library"));
const Login = React.lazy(() => import("./pages/Login"));
const Register = React.lazy(() => import("./pages/Register"));
const Settings = React.lazy(() => import("./pages/Settings"));
const Song = React.lazy(() => import("./pages/Song"));
const Forget = React.lazy(() => import("./pages/Forget"));
const ResetPassword = React.lazy(() => import("./pages/Reset"));
const Feedbacks = React.lazy(() => import("./pages/Feedbacks"));

const PAGES_TO_CACHE = [
  '/login',
  '/forget',
]

function App() {

  const logoUrl = require("./assets/images/logo.svg").default;

  document.head.innerHTML = `
    <link rel="preload" as="image" href="${logoUrl}" />
    <link rel="preload" as="image" href=${images.sheet} />
    <link rel="preconnect" href="${process.env.REACT_APP_BACKEND_URL}" />
  ` + document.head.innerHTML;

  return (
    <div className="app-container">
      {/* <Helmet>
            <link rel="preload" as="image" href={logoUrl} />
      </Helmet> */}
      {!navigator.onLine && <div className="offline">You are offline</div>}
      {navigator.onLine && (
        <React.Fragment>
          <ReactNotifications />
          <BrowserRouter>
            <Suspense fallback={<LoadingSpinner />}>
              <Routes>
                <Route element={<AuthLayout />}>
                  <Route path="/" element={<MainLayout />}>
                    <Route index element={<Dashboard />} />
                    <Route path="/customers" element={<Customers />} />
                    <Route path="/customers/:customerId" element={<CustomerEdit />} />
                    <Route path="/feedbacks" element={<Feedbacks />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="/library/*" element={<Library />} />
                    <Route path="/song/:songId" element={<Song />} />
                  </Route>
                </Route>
                <Route path="/register-beta/:code" element={<RegisterBeta />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forget" element={<Forget />} />
                <Route path="/resetpassword" element={<ResetPassword />} />
                <Route path="/install" element={<Install />} />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Suspense>
          </BrowserRouter>
        </React.Fragment>
      )}
    </div>
  );
}

export default App;
