import React, { useEffect, useState } from 'react';
import LoginContext from "../../store/loginContext";
import classes from "./Install.module.scss";
import Button from '../UI/button/Button';

const PAGES_TO_INSTALL = [
    '/',
    '/?install=true',
    '/login',
    '/forget',
    '/register',
    '/settings?install=true',
    '/library?install=true',
    '/song/1?install=true'
  ]

function PageIframe({page, onLoad}: {page: string, onLoad: () => void}) {
    return (
        <iframe src={page} title={page} onLoad={onLoad} />
    );
}

const Install: React.FC = () => {
    const [currentPage, setCurrentPage] = useState(0);

    const handleLoad = () => {
        if (currentPage === PAGES_TO_INSTALL.length) {
            return;
        }
        setTimeout(() => {
            setCurrentPage(currentPage + 1);
        }, 2000)
    }
    useEffect(() => {
    }, [])
    return (
        <div className={classes.container}>
            <div>
            {/* {PAGES_TO_INSTALL.map(page => <PageIframe page={page} />)} */}
                <PageIframe page={PAGES_TO_INSTALL[currentPage]} onLoad={handleLoad} />
            </div>
            <div className={classes.divOver}>
                
                <div className={classes.title}>
                    {currentPage < PAGES_TO_INSTALL.length ? `Installing ${PAGES_TO_INSTALL[currentPage].replace('?install=true', '')}...` : 'Done'}
                </div>
                {
                    currentPage < PAGES_TO_INSTALL.length ? (
                        <div className={classes.loader}>
                            <div
                                className={classes.loaderBar}
                                style={{ width: `${(currentPage / PAGES_TO_INSTALL.length) * 100}%` }}    
                            />
                        </div>
                    ) :
                        <Button onClick={() => window.location.href = '/'}>Go to login</Button>
                    }
            </div>
        </div>
    );
};

export default Install;