import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import classes from "./Input.module.scss";

interface Props {
  id: string;
  type: string;
  minLength?: number;
  maxLength?: number;
  placeholder?: string;
  classes?: string;
  value?: string;
  ref?: HTMLInputElement;
  readonly?: boolean;
  autocomplete?: string;
  onSubmit?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface InputImperativeHandler {
  focus: () => void;
  focusError: () => void;
  value?: string;
}

// Using React.forwardRef to allow parent components to call methods on this component
const Input = React.forwardRef<InputImperativeHandler, Props>((props, ref) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [value, setValue] = useState(props.value || "");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const borderCut = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLLabelElement>(null);

  const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.currentTarget.value;
    setValue(newValue);
    if (props.onChange) {
      props.onChange(e);
    }
  };

  useEffect(() => {
    if (labelRef.current && borderCut.current) {
      borderCut.current.style.width = `${labelRef.current.offsetWidth}px`;
    }
  }, [labelRef, borderCut]);

  const inputFocused = useCallback(() => {
    inputRef.current?.select();
  }, []);

  const focusInputError = useCallback(() => {
    inputRef.current?.focus();
    inputRef.current?.setAttribute("style", "border:2px solid red");
  }, []);

  useImperativeHandle(ref, () => ({
    focus: inputFocused,
    focusError: focusInputError,
    value: value,
  }));

  const { t } = useTranslation();

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && props.onSubmit) {
      props.onSubmit();
    }
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  return (
    <div className={`${classes.form__control} ${props.classes}`}>
      <div className={classes.form__control__borderCut} ref={borderCut} />
      {props.id && <label ref={labelRef} htmlFor={props.id}>{t(`${props.id}`)}</label>}
      
      <div className={classes.inputWrapper}>
        <input
          ref={inputRef}
          id={props.id}
          minLength={props.minLength}
          maxLength={props.maxLength}
          type={props.type === "password" && isPasswordVisible ? "text" : props.type}
          placeholder={props.placeholder}
          value={value}
          readOnly={props.readonly || false}
          onChange={inputChangeHandler}
          autoComplete={props.autocomplete || "off"}
          onKeyDown={handleOnKeyDown}
        />
        {props.type === "password" && (
          <button
            type="button"
            className={classes.toggleVisibility}
            onClick={togglePasswordVisibility}
            aria-label={isPasswordVisible ? "Hide password" : "Show password"}
          >
            <FontAwesomeIcon
              icon={isPasswordVisible ? faEyeSlash : faEye}
              style={{ color: 'var(--text-color)' }} // Correctly using the CSS variable for icon color
            />
          </button>
        )}
      </div>
    </div>
  );
});

export default Input;
