/*import playButtonSvg from "../assets/images/play-button.svg";
import playButtonPauseSvg from "../assets/images/play-button-pause.svg";
import soundIconSvg from "../assets/images/sound-icon.svg";*/
// repair svg don't know why but the include don't work

const images = {
  // logo: require("../assets/images/digikalaLogo.png"), nathan
  // avt: require("../assets/images/avatar.jpg"),
  // logKey: require("../assets/images/Reset password-pana.svg"),
  // dashboard: require("../assets/images/Revenue-cuate.svg"),
  // notFound: require("../assets/images/Oops 404 Error with a broken robot-cuate.svg"),
  logo: require("../assets/images/logo.svg").default,
  // playButton: playButtonSvg,
  // playButtonPause: playButtonPauseSvg,
  // soundIcon: soundIconSvg,
  playButton: require("../assets/images/play-button.png"),
  playButtonPause: require("../assets/images/play-button-pause.png"),
  soundIcon: require("../assets/images/sound-icon.png"),
  avt: require("../assets/images/avatar.jpg"),
  logKey: require("../assets/images/logo.png"),
  dashboard: require("../assets/images/logo.png"),
  sheet: require("../assets/images/image.png"),
  // notFound: require("../assets/images/logo.png"),
  guitar: require("../assets/images/guitar.png"),
  piano: require("../assets/images/piano.png"),
  drums: require("../assets/images/drums.png"),
  trash: require("../assets/images/trash.png"),
};

export default images;
